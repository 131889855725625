import { all } from "redux-saga/effects";
import { combineReducers } from "redux";
import * as auth from "../app/modules/Auth/_redux/authRedux";
import { rolesSlice } from "../app/modules/Roles/_redux/slice";
import { usersSlice } from "../app/modules/Users/_redux/slice";
import { agentsSlice } from "../app/modules/Agents/_redux/slice";
import { propertiesSlice } from "../app/modules/Properties/_redux/slice";
import { offersSlice } from "../app/modules/Offers/_redux/slice";
import { pagesSlice } from "../app/modules/Pages/_redux/slice";
import { settingsSlice } from "../app/modules/Settings/_redux/slice";
import { ticketPriceTypesSlice } from "../app/modules/TicketPriceTypes/_redux/slice";
import { ticketPricesSlice } from "../app/modules/TicketPrices/_redux/slice";
import { walletsSlice } from "../app/modules/Wallets/_redux/slice";
import { bookingTicketsSlice } from "../app/modules/BookingTickets/_redux/slice";
import { commissionsSlice } from "../app/modules/Commission/_redux/slice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  roles: rolesSlice.reducer,
  agents: agentsSlice.reducer,
  bookingTickets: bookingTicketsSlice.reducer,
  users: usersSlice.reducer,
  properties: propertiesSlice.reducer,
  offers: offersSlice.reducer,
  pages: pagesSlice.reducer,
  settings: settingsSlice.reducer,
  ticketPriceTypes: ticketPriceTypesSlice.reducer,
  ticketPrices: ticketPricesSlice.reducer,
  wallets: walletsSlice.reducer,
  commissions: commissionsSlice.reducer,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
