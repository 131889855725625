import React, { Suspense, lazy } from "react";
import { Redirect, Switch } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { DashboardPage } from "./pages/DashboardPage";

const RolesPage = lazy(() => import("./modules/Roles/pages/index"));
const UsersPage = lazy(() => import("./modules/Users/pages/index"));
const AgentsPage = lazy(() => import("./modules/Agents/pages/index"));
const PropertiesPage = lazy(() => import("./modules/Properties/pages/index"));
const OffersPage = lazy(() => import("./modules/Offers/pages/index"));
const PagesPage = lazy(() => import("./modules/Pages/pages/index"));
const SettingsPage = lazy(() => import("./modules/Settings/pages/index"));
const TicketPriceTypesPage = lazy(() =>
  import("./modules/TicketPriceTypes/pages/index")
);
const TicketPricesPage = lazy(() =>
  import("./modules/TicketPrices/pages/index")
);
const WalletsPage = lazy(() => import("./modules/Wallets/pages/index"));
const CommissionPage = lazy(() => import("./modules/Commission/pages/index"));
const BookingTickets = lazy(() =>
  import("./modules/BookingTickets/pages/index")
);
const UpdateProfilePage = lazy(() =>
  import("./modules/Auth/pages/UpdateProfile")
);
const ChangePasswordPage = lazy(() =>
  import("./modules/Auth/pages/ChangePassword")
);

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <Redirect exact from="/" to="/dashboard" />

        <ContentRoute path="/dashboard" component={DashboardPage} />
        <ContentRoute path="/roles" component={RolesPage} />
        <ContentRoute path="/agents" component={AgentsPage} />
        <ContentRoute path="/users" component={UsersPage} />
        <ContentRoute path="/properties" component={PropertiesPage} />
        <ContentRoute path="/offers" component={OffersPage} />
        <ContentRoute path="/pages" component={PagesPage} />
        <ContentRoute path="/settings" component={SettingsPage} />
        <ContentRoute
          path="/ticket-price-types"
          component={TicketPriceTypesPage}
        />
        <ContentRoute path="/ticket-prices" component={TicketPricesPage} />
        <ContentRoute path="/wallets" component={WalletsPage} />
        <ContentRoute path="/commissions" component={CommissionPage} />
        <ContentRoute path="/update-profile" component={UpdateProfilePage} />
        <ContentRoute path="/change-password" component={ChangePasswordPage} />
        <ContentRoute path="/booking-tickets" component={BookingTickets} />
        <Redirect to="/error/error-v1" />
      </Switch>
    </Suspense>
  );
}
